@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


@layer components {
    .sidebar-icon {
      @apply relative flex items-center justify-center 
      h-12 w-12 mt-2 mb-2 mx-auto  
    bg-gray-800 hover:bg-cyan-400 dark:bg-gray-800 
    text-white hover:text-white
      hover:rounded-xl rounded-3xl
      transition-all duration-300 ease-linear
      cursor-pointer shadow-lg ;
    }
  
    .sidebar-tooltip {
      @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md
      text-white bg-gray-900 
      text-xs font-bold 
      transition-all duration-100 scale-0 origin-left;
    }
  
    .sidebar-hr {
      @apply bg-gray-800 dark:bg-gray-800 
      border border-gray-800 dark:border-gray-800 rounded-full
      mx-2;
    }

}